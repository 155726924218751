/* eslint-disable */
import Head from "next/head";
import React, { useState } from "react";
import { useRouter } from "next/router";
import { useDisclosure, Box, useTheme, VStack, Flex, Badge, Button, Link, useBreakpointValue, Text, Spacer } from "@chakra-ui/react";

import config from "../../config.json";
import MaintenancePage from "../components/UnderMaintenance";
import { Input } from "@/components/Shell";

import { useHistory } from "@/components/ShellCmdHistory/hook";
import { History } from "@/components/ShellCmdHistory/History";

import { saveToStorage, getFromStorage } from "@/hooks/useLocalStorage";
import { init, redirectToChat } from "../utils/cmds/init";
import { Container, Footer } from "@/components/ui";

import useToggleEffect from '@/hooks/useToggleEffect';

interface IndexPageProps {}

const IndexPage: React.FC<IndexPageProps> = ({}) => {
  const {
    history,
    command,
    lastCommandIndex,
    setCommand,
    setHistory,
    clearHistory,
    setLastCommandIndex,
  } = useHistory([]);

  const initialize = React.useCallback(() => setHistory(init()), []);
  
  const [userName, setUserName] = useState("guest");
  const connectedAddress = getFromStorage("wallet")
    ? JSON.parse(getFromStorage("wallet")).address
    : "";
  const inputRef = React.useRef<HTMLInputElement>(null);
  const router = useRouter();

  const theme = useTheme();  
  const fontSize = useBreakpointValue(theme.fontSize.cli.sizes)
  const buttonSize = useBreakpointValue(theme.buttonSize);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ disableType, setDisableType ] = useState(false);

  const { hasEffect } = useToggleEffect();

  React.useEffect(() => {
    clearHistory();
    initialize();
  }, [initialize]);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView();
      inputRef.current.focus({ preventScroll: true });
    }
  }, [history]);

  React.useEffect(() => {
    if (connectedAddress !== "undefined") {
      setUserName(connectedAddress ? connectedAddress : "guest");
    }
  }, [connectedAddress]);

  function handleOpenDiscord(ref) {
    if (ref === "discord") {
      router.push("https://discord.gg/d0xxx")
      // setHistory(redirectToChat());
    }
  }

  const onClickAnywhere = () => {
    inputRef.current.focus();
  };

  function openD0xList(){
    onOpen();
    setDisableType(false);
  }

  function handleCloseD0xList(){
    onClose();
    setDisableType(true);
  }

  const handleX = () => {
    router.push("https://twitter.com/ExpressionLabs")
  }

  return (
    <Box className={hasEffect ? "crt" : ""}>
      <Head>
        <title>{config.title}</title>
        <meta
          property="og:image"
          content={process.env.NEXT_PUBLIC_META_EMBED}
        />
        <meta
          property="og:image:alt"
          content="Your favorite blockchain ASCII parasites!"
        />
        <meta property="og:image:width" content="1000" />
        <meta property="og:image:height" content="1000" />
        <meta property="og:site_name" content="d0x.app" />
        <meta property="og:type" content="object" />
        <meta
          property="og:title"
          content="d0x - Your Favorite Blockchain ASCII Parasites"
        />
        <meta property="og:url" content="https://d0x.app" />
        <meta
          property="og:description"
          content="Your favorite blockchain ASCII parasites!"
        />

        <meta
          name="twitter:image:src"
          content={process.env.NEXT_PUBLIC_META_TWITTER_EMBED}
        />
        <meta name="twitter:site" content="@ExpressionLabs" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="d0x - Your Favorite Blockchain ASCII Parasites"
        />
        <meta
          name="twitter:description"
          content="Your favorite blockchain ASCII parasites!"
        />
      </Head>
      <Flex
        w="100vw"
        h="100vh"
        minHeight="100vh"
        padding="1rem"
        bgColor={theme.colors.dark[500]}
        color={theme.colors.lime[200]}
        justifyContent="left"
        flexDirection="column"
        onClick={onClickAnywhere}
        >
        <Box flex="1" h="100%" overflowY="auto">
          <History history={history} username={userName} />
          <Input
            inputRef={inputRef}
            username={userName}
            command={command}
            history={history}
            lastCommandIndex={lastCommandIndex}
            setCommand={setCommand}
            setHistory={setHistory}
            setLastCommandIndex={setLastCommandIndex}
            clearHistory={clearHistory}
            initialize={initialize}
            openD0xList={openD0xList}
            disableType={disableType}
          />
        </Box>

        <Box textAlign="center" padding="1rem">
          <Box pb="0.5rem">
            <Link fontSize={buttonSize} onClick={() => handleOpenDiscord("discord")}>Discord</Link> |{" "}
            <Link fontSize={buttonSize} onClick={handleX}>
              Twitter
            </Link>
          </Box>
          <Text fontSize={fontSize}>
            Powered by <a target="_blank" href="https://meshjs.dev/">MeshJS</a> on Cardano | © 2024
            Expression Labs
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default IndexPage;
