var walletName = "";

export const setWalletName = (props) => {
  walletName = props;
  return walletName;
};

export const showWalletName = () => {
  return walletName;
};
