import axios from "axios";

import { LoadingDots } from "@/components/ui/LoadingDots";
import { getFromStorage } from "@/hooks/useLocalStorage";

export const micro = async (args: string[0]): Promise<string> => {
  var data;

  try {
    const res = await fetch("/api/micro", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });
    if (!res.ok) {
      const error = await res.text();
      throw new Error(error);
    }
    data = await res.json();
  } catch (err) {
    console.error(err);
  }
  return data;
};

export const d0xlist = (args: string[]) => {
  var result = (
    <>
      Routing to: <div className="yellow inline">/d0xlist</div> <LoadingDots />
    </>
  );

  return result;
};

export const d0xxer = (args: string[]) => {
  var result = (
    <>
      Routing to: <div className="yellow inline">/og/create</div> <LoadingDots />
    </>
  );

  return result;
};

export const oglist = (args: string[]) => {
  var result = (
    <>
      Routing to: <div className="yellow inline">/og/register</div> <LoadingDots />
    </>
  );

  return result;
};