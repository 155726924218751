import { commandExists } from "../utils/commandExists";
import { Box, Text, useBreakpointValue, useTheme } from "@chakra-ui/react";

export const IsValidCommand = ({ command }) => {
  const theme = useTheme();
  const fontSize = useBreakpointValue(theme.fontSize.cli.sizes)

  return (
    <Text fontSize={fontSize} color={commandExists(command) ? theme.colors.lime[200] : theme.colors.red}>
      {command}
    </Text>
  );
};

export default IsValidCommand;
