import { useState, useEffect } from 'react';

const useToggleEffect = () => {
  const [hasEffect, setHasEffect] = useState(true);

  useEffect(() => {
    const storedToggleEffect = localStorage.getItem('toggleEffect');

    if (storedToggleEffect !== null) {
      setHasEffect(JSON.parse(storedToggleEffect));
    }
  }, []);

  const toggleEffect = () => {
    const updatedToggleEffect = !hasEffect;
    setHasEffect(updatedToggleEffect);

    localStorage.setItem('toggleEffect', JSON.stringify(updatedToggleEffect));
  };
  return { hasEffect, toggleEffect };
};

export default useToggleEffect;
