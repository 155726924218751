// List of commands that do not require API calls
import * as bin from "./index";
import changelogs from "./changelogs.json";

import { LoadingDots } from "@/components/ui/LoadingDots";
import { getFromStorage } from "@/hooks/useLocalStorage";

var result = null;

export const about = () => {
  result = (
    <>
      <p>
        <i>Transmitting...</i>
      </p>
      <p>Anticipate all possibilities, prod the anomalies, be assimilated.</p>
      <br />
      <p>
        d0x are procedural ASCII parasites living and breathing on Cardano.
        Exposure is the name of the game.
      </p>
    </>
  );
  return result;
};

export const faq = () => {
  result = (
    <>
      <p>
        <i>_What is this project about?</i>
      </p>
      <p>
        The d0x collection is an ASCII collectible NFT, living forever on the
        Cardano blockchain.
      </p>
      <br />
      <p>
        <i>_What are the plans/roadmaps of d0x?</i>
      </p>
      <p>
        No roadmaps will be promised, although other parts of the project are
        already actively being working on as we speak. Keep a look out!
      </p>
      <br />
      <p>
        <i>_How many pieces will there be and what is the mint price?</i>
      </p>
      <p>
        ▒▒▒▒▒ unique d0x will be released into the wild. More info will be
        released nearer to release date.
      </p>
      <br />
      <p>
        <i>_How do I obtain d0x-list?</i>
      </p>
      <p>Stay tuned on Twitter and Discord for d0x-list opportunities.</p>
      <br />
      <p>
        <i>_How do I reach out for partnership opportunities?</i>
      </p>
      <p>We may not respond to collabs on Discord, so ping us on Twitter! </p>
    </>
  );
  return result;
};

export const help = () => {
  const commands = Object.keys(bin).sort();
  const commdesc = {
    about: "Short introduction",
    changes: "Review the changelogs",
    micro: "Generate a micro-d0x",
    dapp: "Connect your wallet (Future feature)",
    help: "List currently available commands",
    faq: "Wtfaq is this?",
    d0xlist: "Got a d0x-list? Go here to claim it",
    d0xxer: "Craft your d0xxer",
    oglist: "Register your wallet for the OG mint",
  };

  const sp = " ";

  const cmdlist = commands.map((cmd, i) => {
    return (
      <div key={i}>
        {commands[i]}
        {sp.repeat(13 - cmd.length)}:{sp.repeat(1)}
        {commdesc[cmd]}
      </div>
    );
  });

  const result = (
    <div>
      <i>Usage: [COMMAND]</i>
      {cmdlist}
    </div>
  );

  return result;
};

export const changes = async () => {
  const features = Object.values(changelogs.add).map((content, i) => {
    return <div key={i}>- {content}</div>;
  });
  var fixes = Object.values(changelogs.fix).map((content, i) => {
    return <div key={i}>- {content}</div>;
  });

  const result = (
    <div className="">
      <i>Changelog</i>
      <br />
      ---------
      <br />
      {changelogs.ver}
      <br />
      ### New Stuff
      <br />
      {features}
      <br />
      ### Fixes
      <br />
      {fixes}
    </div>
  );

  return result;
};
