// import { useEffect, useState } from 'react';

// const useLocalStorage= (key: string, fallbackValue: any) => {
//   const [value, setValue] = useState(fallbackValue);
//   useEffect(() => {
//       const stored = localStorage.getItem(key);
//       setValue(stored ? JSON.parse(stored) : fallbackValue);
//   }, [fallbackValue, key]);

//   useEffect(() => {
//       localStorage.setItem(key, JSON.stringify(value));
//   }, [key, value]);

//   return [value, setValue] as const;
// }

// export default useLocalStorage;

// save to storage
export const saveToStorage = (key, value) => {
  if (typeof window !== "undefined") {
    return window.localStorage.setItem(key, value);
  }
};

// get from storage
export const getFromStorage = (key) => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }
};

// delete from storage
export const deleteFromStorage = (key) => {
  if (typeof window !== "undefined") {
    return window.localStorage.removeItem(key);
  }
};
