import React from "react";
import { showWalletName } from "../utils/setWalletName";

import {
  Box,
  useTheme,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

export const User = ({ user = undefined }: { user?: string }) => {
  const theme = useTheme();
  const fontSize = useBreakpointValue(theme.fontSize.cli.sizes)
  var addr = showWalletName().toString();

  if (user && user.length > 5) {
    addr = user;
  }

  function shorten(userId) {
    return userId.slice(0, 6) + "-" + userId.slice(-6);
  }

  return (
    <Box>
      <Text className="inline" fontSize={fontSize} color={theme.colors.lime[200]}>
        {!addr ? user : shorten(addr)}
      </Text>
      <Text fontSize={fontSize} className="opacity-75 inline">
        {" "}
        &gt;
      </Text>
    </Box>
  );
};

export default User;
