import React from "react";

import TypeWriter from "../typewriter/Typewriter";
// Init
export const init = () => {
  var result = null;

  result = (
    <>
      <div className="HEADER inline">
        {/* {b3()} */}
        <TypeWriter text="init d0x.app" delay={250} speed={-5} cursor={false} />
      </div>
      <i>
        <div className="overlap mb-7">
          <TypeWriter text={b1()} delay={900} speed={-30} cursor={false} />
        </div>
        <TypeWriter
          text={b2()}
          delay={450}
          speed={-10}
          random={20}
          cursor={false}
        />
      </i>
      <TypeWriter text={b3()} delay={650} speed={-30} cursor={false} />
    </>
  );

  return result;
};

export const b1 = () => {
  const boot1 = [
    `░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░`,
    `
  `,
  ];

  return boot1;
};

export const b2 = () => {
  const boot2 = [
    "",
    `██████████████████████████████████████`,
    "Compiled successfully with no errors!",
    `Welcome!`,
  ];

  return boot2;
};

export const b3 = () => {
  const ascii = `┎┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┒
██████╗   ██████╗  ██╗   ██╗
██╔══██╗ ██╔═████╗ ╚██╗ ██╔╝
██║  ██║ ██║██╔██║  ╚████╔╝  
██║  ██║ ████╔╝██║  ██╔═██╗   
██████╔╝ ╚██████╔╝ ██╔╝  ██╗
╚═════╝   ╚═════╝  ╚═╝   ╚═╝
┖┄┄┄┄┄┄┄┄┄ v0.1.2 ┄┄┄┄┄┄┄┄┄┚
Bringing to the space fresh new experiences.    
Type 'help' to see the list of available commands.`;

  const boot3 = ["", ``, ``, ascii];

  return boot3;
};

export const b4 = () => {
  const ascii = `┌──────────────────────────────────┐
│██ ██████╗   ██████╗  ██╗   ██╗ ██│
│██ ██╔══██╗ ██╔═████╗ ╚██╗ ██╔╝ ██│
│██ ██║  ██║ ██║██╔██║  ╚████╔╝  ██│
│██ ██║  ██║ ████╔╝██║  ██╔═██╗  ██│
│██ ██████╔╝ ╚██████╔╝ ██╔╝  ██  ██│
│██ ╚═════╝   ╚═════╝  ╚═╝   ╚═╝ ██│
│██ A web3 experiment on Cardano ██│
└──────────────────────────────────┘ 
----------
d0x aims to bring to the space fresh new experiences.
-----------        
Type 'help' to see the list of available commands.`;

  const boot3 = ["", ``, ``, ascii];

  return boot3;
};

export const redirectToChat = () => {
  var result = null;

  result = (
    <>
      <div className="HEADER inline">
        <TypeWriter
          text="Discord is not open to public yet."
          delay={0}
          speed={-15}
          cursor={false}
        />
      </div>
    </>
  );

  return result;
};
