/* eslint-disable */
import { useEffect, useState } from "react";
import { useWallet, useWalletList, useRewardAddress } from "@meshsdk/react";
import { saveToStorage, deleteFromStorage } from "../hooks/useLocalStorage";

import { Box, Grid, useTheme, Link, Text } from "@chakra-ui/react";

import { setWalletName } from "../utils/setWalletName";

import { Heading, SubHeading } from "@/components/ui";
import { LoadingDots } from "./ui";

export const Connector = ({
  isClickable,
  action = undefined,
  label = "Connect Wallet",
  onConnected = undefined,
}: {
  isClickable?: boolean;
  action?: string[];
  label?: string;
  onConnected?: Function;
}) => {
  const wallets = useWalletList();
  const address = useRewardAddress();
  const theme = useTheme();
  const isActionConnect = action[0] === "-c" || action[0] === "-connect";
  const isActionDisconnect = action[0] === "-d" || action[0] === "-disconnect";

  const { connect, connecting, connected } = useWallet();
  const [walletError, setWalletError] = useState(false);
  const [connectResponse, setConnectResponse] = useState("");

  const [installedWallets, setInstalledWallets] = useState([]);

  useEffect(() => {
    const updatedWallets = wallets.map(wallet => wallet.name.toLowerCase());

    if (updatedWallets.includes("vespr")) {
      setInstalledWallets(["vespr"]);
    } else {
      setInstalledWallets(updatedWallets);
    }
  }, [wallets]);

  useEffect(() => {
    if (action.length === 0 || !isActionConnect || !isActionDisconnect) {
      setWalletError(true);
      setConnectResponse("Usage: dapp [options]");
    }
    if (isActionConnect) {
      setWalletError(false);
      if (action.length > 1) {
        try {
          connect(action[1]);
          saveToStorage(
            "wallet",
            JSON.stringify({ name: action[1], address: address }),
          );
          setConnectResponse(`Connected to: ${(address && address) || "..."}`);
        } catch (e) {
          console.error(e);
        }
      } else {
        setConnectResponse("Usage: dapp [options]");
        setWalletError(true);
      }
    } else if (isActionDisconnect) {
      setWalletError(false);
      if (connected) {
        if (action.length === 1) {
          deleteFromStorage("wallet");
          setConnectResponse("Disconnected.");
        } else {
          setWalletError(true);
          setConnectResponse("Disconnect not allowed to have arguments.");
        }
      } else {
        setConnectResponse("You are not connected in the first place.");
      }
    }
  }, [action, address, wallets]);

  // useEffect(() => {
  //   if (action.length > 1 && error !== undefined) {
  //     setWalletError(true);
  //   }
  // }, [action, error]);

  useEffect(() => {
    if (connected && onConnected) {
      onConnected();
      setConnectResponse("");
      setWalletName(address);
    } else if (!connected) {
      setWalletName("");
    }
  }, [connected, onConnected, address]);

  return (
    <>
      {isClickable ? (
        // for chat
        <div className="">
          {connecting && !connected && (
            <Text color={theme.colors.light[300]}>
              Connecting
              <LoadingDots />
            </Text>
          )}

          {walletError && (
            <>
              <Heading>Error:</Heading> Failed to connect to wallet.
            </>
          )}
          {connected && (
            <Text color={theme.colors.lime[200]}>Connected!</Text>
          )}
          {connected ? ( // if connected
            <>
              {isActionConnect ? (
                <>You are connected!</>
              ) : (
                <>
                  {/* {address && (<Text color={theme.colors.light[100]}>Connected to: <Text color={theme.colors.lime[200]}>{address} <a onClick={onDisconnect}>Disconnect</a></Text></Text>)} */}
                </>
              )}
            </>
          ) : (
            // if not connected
            <>
              {action ? (
                <>
                  {isActionDisconnect && <SubHeading>Disconnected!</SubHeading>}
                </>
              ) : (
                <>
                  {!connecting && isClickable && (
                    <div className="">
                      <Text color={theme.colors.orange}>
                        Authentication:
                      </Text>
                      <Text color={theme.colors.light[100]}>
                        Connect a wallet to continue.
                      </Text>
                      {!installedWallets.length && (
                        <>No wallet extension found!</>
                      )}
                      {installedWallets.map((v, i) => {
                        return (
                          <Link
                            padding="0rem 0.4rem"
                            color={theme.colors.lime[200]}
                            key={i}
                            onClick={() => connect(v)}
                          >
                            {v}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ) : (
        // for homepage
        <div className="">
          {connectResponse}
          {walletError && (
            <>
              <div className="tab">
                -c, -connect
                WALLET&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Connect your
                wallet to access dapp features
              </div>
              <div className="tab">
                -d,
                -disconnect&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Unlink
                wallet
              </div>
              {!installedWallets.length ? (
                <>No wallet extension found!</>
              ) : (
                <div>
                  <br />
                  Detected installed wallets: {installedWallets.join(", ")}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
