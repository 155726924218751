import React from "react";
import { History as HistoryInterface } from "./interface";
import { User } from "../ShellUser";

import { Box, useTheme, Text, Stack, useBreakpointValue } from "@chakra-ui/react";

export const History: React.FC<{
  history: Array<HistoryInterface>;
  username: string;
}> = ({ history, username }) => {
  const theme = useTheme();
  const fontSize = useBreakpointValue(theme.fontSize.cli.sizes)
  const fontPadding = useBreakpointValue({base: "0.25rem 1rem", sm: "0.25rem 1rem", md: "0.25rem 1rem", lg: "0.25rem 1rem"})
  const cmdPadding = useBreakpointValue({base: "0.3rem 0", sm: "0.15rem 0", md: "0", lg: "0"})
  const lineHeight = useBreakpointValue(theme.fontSize.cli.lineHeight)
  let historyLog: Array<string> = [];

  try {
    for (let i = 0; i < Object.entries(history).length; i++) {
      historyLog.push(Object.entries(history[i])[3][1]);
    }
  } catch {}

  return (
    <Box w="100%">
      {history.map((entry: HistoryInterface, index: number) => (
        <Box key={entry.command + index} color={theme.colors.light[200]}>
          <Stack direction="row">
            <User user={username} />
            <Text fontSize={fontSize} padding={cmdPadding} >{entry.command}</Text>
          </Stack>
          <Text fontSize={fontSize} padding={fontPadding} lineHeight={lineHeight}>{historyLog[index]}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default History;
