import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";

import { shellFunc } from "../utils/shellFunc";
import { handleTabCompletion } from "../utils/tabCompletion";
import { User } from "./ShellUser";
import { getFromStorage } from "../hooks/useLocalStorage";

import { IsValidCommand } from "./IsValidCommand";

import {
  Box,
  useTheme,
  VStack,
  HStack,
} from "@chakra-ui/react";

export const Input = ({
  inputRef,
  username,
  command,
  history,
  lastCommandIndex,
  setCommand,
  setHistory,
  setLastCommandIndex,
  clearHistory,
  initialize,
  openD0xList,
  disableType,
}) => {
  const router = useRouter();
  const theme = useTheme();

  const myDivRef = useRef(null);
  const [canType, setCanType] = useState(true);
  const connectedAddress = getFromStorage("wallet")
    ? JSON.parse(getFromStorage("wallet")).address
    : "";

  const onSubmit = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const commands: [string] = history
      .map(({ command }) => command)
      .filter((command: string) => command);

    if (event.key === "c" && event.ctrlKey) {
      event.preventDefault();
      setCommand("");
      setHistory("");
      setLastCommandIndex(0);
    }

    if (event.key === "l" && event.ctrlKey) {
      event.preventDefault();
      clearHistory();
    }

    if (event.key === "Tab") {
      event.preventDefault();
      handleTabCompletion(command, setCommand);
    }

    if (event.key === "Enter" || event.code === "13") {
      var cmdSplitArg = command.split(" ");
      // var validChannels = process.env.NEXT_PUBLIC_CHANNELS.split(' ')

      event.preventDefault();
      setLastCommandIndex(0);
      await shellFunc(command, setHistory, clearHistory, setCommand);

      myDivRef.current.scrollIntoView();

      // goto whitelist page
      if (cmdSplitArg[0] === "d0xlist") {
        setCanType(!disableType);
        setTimeout(() => {
          openD0xList();
          
          clearHistory();
          initialize();
          
          setCanType(true);
          // router.replace(`/d0xlist`);
        }, 2000);
      }

      if (cmdSplitArg[0] === "d0xxer") {
        setCanType(!disableType);
        setTimeout(() => {
          router.push(`/og/create`);
        }, 2000);
      }
      
      if (cmdSplitArg[0] === "oglist") {
        setCanType(!disableType);
        setTimeout(() => {
          router.push(`/og/create`);
        }, 2000);
      }

      // if (cmdSplitArg[0] === 'chat'){
      //   if (cmdSplitArg[1] === undefined) {
      //     // do nothing
      //   } else if (!cmdSplitArg[1]){
      //     return
      //   } else if (cmdSplitArg[1] === 'host'){
      //     setCanType(false)
      //     setTimeout(() => {
      //       router.replace(`/host`)
      //     }, 2000);
      //   } else if (validChannels.includes(cmdSplitArg[1])) {
      //     setCanType(false)
      //     setTimeout(() => {
      //       router.replace(`/channel/${cmdSplitArg[1]}`)
      //     }, 2000);
      //   } else {
      //     return
      //   }
      // }

      // if (cmdSplitArg[0] === 'zetasploit' && process.env.NEXT_PUBLIC_ZETASPLOIT_ONLINE === 'true'){
      //   if (connectedAddress.includes('stake1')) {
      //     setCanType(false)
      //     setTimeout(() => {
      //       router.replace(`/zetasploit/${username}`)
      //     }, 2000);
      //   }
      // }
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (!commands.length) {
        return;
      }
      const index: number = lastCommandIndex + 1;
      if (index <= commands.length) {
        setLastCommandIndex(index);
        setCommand(commands[commands.length - index]);
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (!commands.length) {
        return;
      }
      const index: number = lastCommandIndex - 1;
      if (index > 0) {
        setLastCommandIndex(index);
        setCommand(commands[commands.length - index]);
      } else {
        setLastCommandIndex(0);
        setCommand("");
      }
    }
  };

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCommand(value);
  };

  return (
    <Box>
      {
        canType ? (
          <Box>
            <HStack
              ref={myDivRef}
            >
              <User user={username} /> <IsValidCommand command={command} />
              <Box className="blinking-cursor inline" />
              <input
                ref={inputRef}
                className={`invis-input`}
                value={command}
                onChange={onChange}
                autoFocus
                onKeyDown={onSubmit}
              />
            </HStack>
          </Box>
        ) : (
          <>
            <input ref={inputRef} className={`invis-input`} />
          </>
        )
      }
    </Box>
  );
};

export default Input;
